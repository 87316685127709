/* eslint-disable */

import '../styles/css/45-south-take-2.webflow.css'
import '../styles/css/normalize.css'
import '../styles/css/webflow.css'
import React from 'react';
import Obfuscate from 'react-obfuscate';

class ContactView extends React.Component {

  render() {

    return (
      <span>
        <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet"></link>
        <script src="https://polyfill.io/v3/polyfill.min.js?features=default"></script>
        <span className="af-view">
 
          <div>
            <div className="af-class-section af-class-header-contact af-class-wf-section">
              <div className="af-class-div-block-5">
                <h1 className="af-class-heading-12-seasonal">Get in Touch</h1>
                <h1 className="af-class-heading-13-_seasonal">Contact us</h1>
              </div>
            </div>
      
            <div className="af-class-section-2-contact af-class-wf-section">
              <div className="w-row">
                <div className="af-class-column-15 w-col w-col-6">
                  <h4 className="af-class-heading-28">Physical Address</h4>
                  <div className="af-class-text-block-11">Corner Ord Road &amp;&nbsp;State Highway 6, Cromwell, New Zealand</div>
                  <h4 className="af-class-heading-29">Postal Address</h4>
                  <div className="af-class-text-block-11">PO&nbsp;Box 46<br />Cromwell 9342</div>
                  <h4 className="af-class-heading-29">Phone</h4>
                  <div className="af-class-text-block-11">+64 3 445 1402</div>
                  <h4 className="af-class-heading-29">Employment Phone</h4>
                  <div className="af-class-text-block-11">020 5906 3390</div>
                </div>
                <div className="af-class-column-18 w-col w-col-6"> 
                <iframe width="100%" height="350" loading="lazy" allowFullScreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJnwa7TNQq1akRgi70kTTyG7w&key=AIzaSyCPwTh75KFqKvblXchuvIu9JlTvvBmXEtU"></iframe>
                
                </div>
              </div>
            </div>
            <div className="af-class-section-13 af-class-wf-section">
              <div className="af-class-div-block-12">
                <div className="af-class-columns-8 w-row">
                  <div className="af-class-column-20 w-col w-col-6">
                    <div className="w-row">
                      <div className="af-class-column-22 w-col w-col-4 w-col-small-6"><img src="images/email.png" alt="picture" className="af-class-image-13" /></div>
                      <div className="af-class-column-23 w-col w-col-8 w-col-small-6">
                        <h2 className="af-class-heading-30">Sales Enquiries</h2>
                        <div className="af-class-text-block-12">
                          
                        <Obfuscate
      email="richard@45s.co.nz"
      headers={{
        subject: 'Website Sales Enquiry',
      }}
    />
    </div>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-column-21 w-col w-col-6">
                    <div className="af-class-columns-5 w-row">
                      <div className="af-class-column-32 w-col w-col-4 w-col-small-6"><img src="images/jobs.png" alt="picture" className="af-class-image-13" /></div>
                      <div className="af-class-column-23 w-col w-col-8 w-col-small-6">
                        <h2 className="af-class-heading-30">Job Enquiries</h2>
                        <div className="af-class-text-block-12">           <Obfuscate
      email="employment@45s.co.nz"
      headers={{
        subject: 'Website Job Enquiry',
      }}
    />
    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-section-5-contact af-class-wf-section">
              <div className="af-class-container-3 w-container" />
              <div className="af-class-columns-4 w-row">
                <div className="af-class-column-11 w-col w-col-4 w-col-tiny-tiny-stack w-col-medium-4 w-col-small-small-stack"><img src="images/Tim.png" alt="picture" className="af-class-image-28" />
                  <h3 className="af-class-heading-16">Tim Jones</h3>
                  <div className="af-class-text-block-4">CEO</div>
                  <a className="af-class-link-2">           
                  <Obfuscate
      email="tim.jones@45s.co.nz"
      headers={{
        subject: 'Website Enquiry',
      }}
    />
    </a>
                </div>
                <div className="af-class-column-13 w-col w-col-4 w-col-tiny-tiny-stack w-col-medium-4 w-col-small-small-stack"><img src="images/Richard.png" alt="picture" className="af-class-image-30" />
                  <h3 className="af-class-heading-16">Richard Cameron</h3>
                  <div className="af-class-text-block-4">Sales &amp; Marketing Manager</div>
                  <a className="af-class-link-2">                        
                  <Obfuscate
      email="richard@45s.co.nz"
      headers={{
        subject: 'Website Sales Enquiry',
      }}
    />
    </a>
                </div>
                <div className="af-class-column-12 w-col w-col-4 w-col-tiny-tiny-stack w-col-medium-4 w-col-small-small-stack"><img src="images/Joy_new_2023.png" alt="picture" className="af-class-image-29" />
                  <h3 className="af-class-heading-16">Joy Gillespie</h3>
                  <div className="af-class-text-block-4">Finance &amp; Administration Manager</div>
                  <a className="af-class-link-2">                
                  <Obfuscate
      email="joy.gillespie@45s.co.nz"
      headers={{
        subject: 'Website Enquiry',
      }}
    />
    </a>
                </div>
                
              </div>
            </div>
            <div className="af-class-section_four1 af-class-wf-section">
              <div className="af-class-div-block-3-copy">
                <div className="af-class-div-block-4-copy af-class-cta-about">
                  <div>
                    <h2 className="af-class-heading-10">Looking for<br />Seasonal&nbsp;Work?</h2>
                    <a href="seasonalwork" className="af-class-button-4-contact w-button">LEARN&nbsp;MORE</a><img src="images/we-look-forward-to-hearing-from-you.png" alt="picture" className="af-class-image-7" />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-embed w-script">
            </div>
            {/* [if lte IE 9]><![endif] */}
            {/*  Google Tag Manager (noscript)  */}
            <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NCJNF4D" height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
            {/*  End Google Tag Manager (noscript)  */}
          </div>
        </span>
      </span>
    )
  }
}

export default ContactView

/* eslint-enable */