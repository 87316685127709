import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/css/45-south-take-2.webflow.css'
import '../styles/css/normalize.css'
import '../styles/css/webflow.css'
import React from 'react';
import Obfuscate from 'react-obfuscate';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function FooterMobile() {
  return (
    <span className="af-view">
    <div>
      <div className="af-class-section_footer af-class-wf-section">
     
          <div className="w-col w-col-13">
            <a href="about" className="af-class-link">About us</a>
            <a href="seasonalwork" className="af-class-link">Seasonal Work</a>
            <a href="exports" className="af-class-link">Exports</a>
            <a href="contact" className="af-class-link">Contact Us</a>
            <a href="apply" className="af-class-link">Apply</a>
            <hr></hr>
            <h5 className="af-class-heading-9">Physical Address</h5>
            <div className="af-class-text-block-mobile">Corner Ord Road &amp; State Highway 6</div>
            <div className="af-class-text-block-mobile">Cromwell 9384, New Zealand</div>
            <h5 className="af-class-heading-9">Postal Address</h5>
            <div className="af-class-text-block-mobile">PO&nbsp;Box 46</div>
            <div className="af-class-text-block-mobile">Cromwell 9342, New Zealand</div>
          
       
            <h5 className="af-class-heading-9">Sales Enquiries</h5>
            <div className="af-class-text-block-mobile">
            <Obfuscate
      email="richard@45s.co.nz"
      headers={{
        subject: 'Website Sales Enquiry',
      }}
    />
              </div>
            <h5 className="af-class-heading-9">Job Enquiries</h5>
            <div className="af-class-text-block-mobile">
            <Obfuscate
      email="employment@45s.co.nz"
      headers={{
        subject: 'Website Job Enquiry',
      }}
    />
              </div>
            <h5 className="af-class-heading-9">Phone</h5>
            <div className="af-class-text-block-mobile">
            <Obfuscate
      tel="+64 3 445 1402"
    />
              </div>
    
          </div>
         
        <Row className="d-flex justify-content-centre align-items-center ">

         <Col className="d-flex justify-content-centre align-items-center ">
          <a href="https://www.facebook.com/45SouthCherries/" className="w-inline-block">
            <img src="images/facebook.svg" loading="lazy" alt="facebook link" className="af-class-facebook_link" />
            </a>
        </Col>
        <Col style={{paddingTop:10, paddingBottom:10}} className="d-flex justify-content-centre" xs={5}>
            <img src="images/45south-logo-black.svg" alt="Logo-black" className="af-class-image-5" />
        </Col>
        <Col className="d-flex justify-content-centre align-items-center ">
            <div className="af-class-text-block-3">© 2022</div>
        </Col>
        
        </Row>
      </div>
      <div className="w-embed w-script">
      </div>
      {/* [if lte IE 9]><![endif] */}
      {/*  Google Tag Manager (noscript)  */}
      <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NCJNF4D" height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
      {/*  End Google Tag Manager (noscript)  */}
    </div>
    
  </span>
  );
}

export default FooterMobile;