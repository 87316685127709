import React from 'react'

class ExportsView extends React.Component {

  render() {


    return (
      <span>
        <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet"></link>
        <span className="af-view">
          <div>
            <div className="af-class-section af-class-wf-section">
              <div className="af-class-div-block-5">
                <h1 className="af-class-heading-12-seasonal">45 South Management</h1>
                <h1 className="af-class-heading-13-_seasonal">Exports</h1>
              </div>
            </div>
            <div className="af-class-section-2-exports af-class-wf-section">
              <h2 className="af-class-heading-19">New Zealand's largest grower and packer of fresh cherries.</h2>
              <p className="af-class-paragraph-16">45 South Cherries owns and manages over 300ha of orchards in the Cromwell basin of Central Otago, New Zealand. These orchards produce over 30% of New Zealand export cherries which are enjoyed globally throughout China, South East Asia, and North America.<br /><br />45 South Cherries is one of New Zealand’s largest cherry exporters and the largest grower and packer of cherries. Central Otago’s unique climate and growing conditions produce the best cherries in the world. We partner with the best growers and distribution networks to provide the best cherries to global markets within 24-48 hours of being picked.</p>
              <div>
                <h2 className="af-class-heading-19">Unique climate and growing conditions produce delicious cherries.</h2>
                <div className="w-row">
                  <div className="af-class-column-39 w-col w-col-6">
                    <p className="af-class-paragraph-19">Central Otago is known globally as a world of difference. The southernmost cherry growing region in the world features Schist clad mountain ranges, sun drenched valleys and desert like hills with extremes in climate and seasons. The cold frosty winters cleanse the trees and the spring blossoms signal the return of nature's energy, leading into hot dry summers with extremely long sunshine hours and cool evenings. Cherries thrive in this land of diverse seasonal contrasts; crystal clear mountain water feeds into mineral-rich, semi arid soils producing an incomparable sweetness.</p>
                  </div>
                  <div className="af-class-column-40 w-col w-col-6">
                    <p className="af-class-paragraph-19">45 South Cherries manages, produces and exports a wide range of cherry varieties. Cherries ripen at varying times throughout the harvest season depending on the variety, with harvest beginning in early December and extending through until early February depending on weather conditions. Due to our extensive growing network, 45 South Cherries is the only company in New Zealand able to supply cherries for export during the entire harvest season.<br /><br />Key export varieties include seven of our most popular: Chelan, Sonnet, Stella, Lapin, Sweetheart and Staccato. 45 South Cherries also hold the exclusive rights to grow and export the late-ripening cherry variety Sentennial, a juicy, ruby-red cherry of the highest quality. Growing the Sentennial variety allows 45 South Cherries to supply markets longer than anyone else in New Zealand.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-section-14 af-class-wf-section" />
            <div className="af-class-section-15 af-class-wf-section">
              <h2 className="af-class-heading-19">World-class equipment &amp; systems supply cherries to markets across the globe.</h2>
              <p className="af-class-paragraph-16">45 South Cherries can deliver cherries anywhere in the world within 24-48 hours of the crop being picked. This time frame is made possible by significant investment in our equipment, employment and distribution network. 45 South Cherries hire over 500 seasonal workers to pick and pack our best cherries during harvest. We have one of the largest cherry packhouses in New Zealand featuring two state-of-the-art optical graders, and invest in innovative packaging and supply chain management.<br /><br />We partner with transport operators offering premium chilled trucking facilities to carry our prized crop to the airport. Our cherries are then packed in chilled containers, loaded onto air freight and carried to our distributors and retailers around the world.</p>
            </div>
            <div className="af-class-section-12 af-class-wf-section">
              <div className="af-class-div-block-10">
                <div className="af-class-div-block-11">
                  <h1 className="af-class-heading-27">We're always keen to talk about our export options</h1>
                  <div className="w-clearfix">
                    <a href="contact" className="af-class-footer_action_button w-button">CONTACT&nbsp;US</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-embed w-script">
            </div>
            {/* [if lte IE 9]><![endif] */}
            {/*  Google Tag Manager (noscript)  */}
            <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NCJNF4D" height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
            {/*  End Google Tag Manager (noscript)  */}
          </div>
        </span>
      </span>
    )
  }
}

export default ExportsView

/* eslint-enable */