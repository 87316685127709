/* eslint-disable */

import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';

function IndexView() {
  const [show,setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
    return (
      <span>
<link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet"></link>
        <span className="af-view">
          <div>
            <div className="af-class-section_top af-class-wf-section">

              <div className="af-class-columns w-row">
                <div className="af-class-column w-col w-col-7">
                  <div className="af-class-header_text">
                    <h3 className="af-class-heading">Welcome to the home of</h3>
                  </div>
                  <div className="af-class-header_text_box">
                    <div className="af-class-text-block">New Zealand's finest export cherries.</div>
                  </div>
                </div>
                <div className="w-col w-col-5">
                  <div className="af-class-video_div">
                    <a href="https://www.youtube.com/watch?v=9sAagzBAFPY" className="af-class-link-block-2 w-inline-block" />
                    <a className="w-inline-block w-lightbox"><img src="images/45south-play-icon.svg" alt="picture" role="button" onClick={() => handleShow() } className="af-class-image-24" />
                    </a>
                    <h1 className="af-class-heading-2">Watch our video</h1>

                    <Modal show={show} onHide={handleClose}>
        <Modal.Body><iframe width="460" height="315" src="https://www.youtube.com/embed/O9UGT3KuKM0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen" 
        msallowfullscreen="msallowfullscreen" 
        oallowfullscreen="oallowfullscreen" 
        webkitallowfullscreen="webkitallowfullscreen"></iframe></Modal.Body>
      </Modal>
        
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-section_one af-class-wf-section">
              <div className="af-class-columns-7 w-row">
                <div className="af-class-column-2 w-col w-col-6">
                  <h1 className="af-class-heading-3">Our Story</h1>
                  <h2 className="af-class-heading-4">About 45 South</h2>
                  <p className="af-class-paragraph">45 South Cherries owns and manages over 300ha of orchards in the Cromwell area of Central Otago, New Zealand. Established in 1984, we’re the leader in growing, picking, packing and distributing NZ’s best cherry varieties. 45 South Cherries partners with the most skilled growers to ensure we offer the highest quality cherries for export.<br /></p>
                  <a href="about" className="af-class-button w-button">ABOUT&nbsp;US</a>
                </div>
                <div className="af-class-column-29 w-col w-col-6"><img src="images/packhouse-overview.jpg" width={555} height={418} srcSet="images/packhouse-overview-p-500.jpeg 500w, images/packhouse-overview-p-800.jpeg 800w, images/packhouse-overview-p-1600.jpeg 1600w, images/packhouse-overview.jpg 1920w" sizes="(max-width: 479px) 93vw, (max-width: 767px) 92vw, (max-width: 991px) 48vw, 35vw" alt="picture" className="af-class-homepage-top-img2" /></div>
              </div>
            </div>
            <div className="af-class-section_two af-class-wf-section">
              <div className="af-class-columns-6 w-row">
                <div className="af-class-column-25 w-col w-col-6"><img src="images/homepage-img2.jpg" height={418} width={555} srcSet="images/homepage-img2-p-500.jpeg 500w, images/homepage-img2.jpg 555w" sizes="(max-width: 767px) 100vw, (max-width: 991px) 48vw, 35vw" alt="picture" className="af-class-homepage-top-img2 af-class-homepage-img2" /></div>
                <div className="af-class-column-27 w-col w-col-6">
                  <h1 className="af-class-heading-3">Work with us</h1>
                  <h2 className="af-class-heading-4">Seasonal Work</h2>
                  <p className="af-class-paragraph">Throughout our harvest season each year, we employ over 500 seasonal workers to help ensure the best fruit is picked, packed and distributed efficiently.</p>
                  <a href="seasonalwork" className="af-class-button w-button">LEARN&nbsp;MORE</a><img src="images/Application-Tick.svg" alt="picture" className="af-class-image-6" />
                </div>
              </div>
              <div className="af-class-column_export w-row">
                <div className="af-class-column-2 w-col w-col-6">
                  <h1 className="af-class-heading-3">Our Cherries</h1>
                  <h2 className="af-class-heading-4">Exports</h2>
                  <p className="af-class-paragraph">45 South is one of New Zealand’s largest cherry exporters. 45 South produces over 30% of New Zealand’s export cherries which are marketed throughout China, South East Asia, USA, Canada and New Zealand. Our cherries are recognized both in New Zealand and around the world as being of the highest quality.<br /></p>
                  <a href="exports" className="af-class-button w-button">OUR&nbsp;EXPORTS</a>
                </div>
                <div className="af-class-column-29 w-col w-col-6"><img src="images/webp/about-45-south-img-with-mask.webp" width={555} height={418} srcSet="images/about-45-south-img-with-mask-p-500.jpeg 500w, images/about-45-south-img-with-mask.jpg 555w" sizes="(max-width: 479px) 93vw, (max-width: 603px) 92vw, (max-width: 767px) 555px, (max-width: 991px) 48vw, 35vw" alt="picture" className="af-class-export_img" /></div>
              </div>
            </div>
            <div className="af-class-wf-section">
              <div className="w-container">
                <h1 className="af-class-heading-5">We work closely with</h1>
                <h1 className="af-class-heading-6">Our Partners</h1>
              </div>
              <div className="w-layout-grid af-class-grid">
                <a id="w-node-_82c6a114-f2c6-2195-91ab-bb5ea608f802-77304b1d" href="http://www.summerfruitnz.co.nz" target="_blank" className="w-inline-block"><img src="images/summerfruit.jpg" alt="Summerfruit New Zealand" /></a>
                <a id="w-node-b79958f6-d668-4f2f-83ae-0b0fd0864a5f-77304b1d" href="http://www.mgmarketing.co.nz" target="_blank" className="w-inline-block"><img src="images/mg.png" alt="mg marketing" width="100" height="100"/></a>
                <a id="w-node-_65a45660-7d58-17c7-4cc1-2d72ed66537e-77304b1d" href="http://www.rd8.co.nz" target="_blank" className="w-inline-block"><img src="images/RD8.jpg" alt="picture" /></a>
                <a id="w-node-_7d98bf51-d92c-25ad-7fe5-aac53a63d9a2-77304b1d" href="http://yiannis.international/" target="_blank" className="w-inline-block"><img src="images/Yiannis.jpg" alt="yiannis" className="af-class-image-4" /></a>
                <a id="w-node-c0f8e439-2ceb-cf59-8e88-545b12c77597-77304b1d" href="http://www.centralotagofruit.co.nz" target="_blank" className="w-inline-block"><img src="images/Central-Otafo-Premium-Fruit-ltd.jpg" alt="Central Otago Fruit" className="af-class-image-25" /></a>
                <a id="w-node-d2d8194b-5bfa-f966-a159-4ee66c81f214-77304b1d" href="https://www.centralotagonz.com/" target="_blank" className="w-inline-block"><img src="images/Central-Otago.jpg" alt="Central Otago" className="af-class-image-26" /></a>
                <a href="http://www.foodstuffs.co.nz" target="_blank" className="w-inline-block"><img src="images/Foodstuffs.jpg" alt="Foodstuffs" className="af-class-image-27" /></a>
              </div>
            </div>
            <div className="af-class-section_four1 af-class-wf-section">
              <div className="af-class-div-block-3">
                <div className="af-class-div-block-4">
                  <div>
                    <h2 className="af-class-heading-10">Have a Question?<br />Let's Chat</h2>
                    <a href="contact" className="af-class-button-2 w-button">CONTACT&nbsp;US</a><img src="images/we-look-forward-to-hearing-from-you.png" alt="picture" className="af-class-image-7" />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-embed w-script">
            </div>
            {/* [if lte IE 9]><![endif] */}
            {/*  Google Tag Manager (noscript)  */}
            <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NCJNF4D" height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
            {/*  End Google Tag Manager (noscript)  */}
          </div>
        </span>
      </span>
    )
  
}

export default IndexView

/* eslint-enable */