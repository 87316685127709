import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
BrowserRouter,
Routes,
Route,
} from "react-router-dom";
import About from './views/AboutView';
import Contact from './views/ContactView';
import Exports from './views/ExportsView';
import SeasonalWork from './views/SeasonalWorkView';
import Index from './views/IndexView';
//import Apply from './views/ApplyView';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} >
       <Route index element={<Index />} />
       <Route path="about" element={<About />} />
       <Route path="contact" element={<Contact />} />
       <Route path="exports" element={<Exports />} />
       <Route path="seasonalwork" element={<SeasonalWork />} />
       <Route path="apply" element={<Apply />} />
      </Route>
    </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

function Apply() {
  // 👇️ redirect to external URL when applications are open
  
  window.location.replace('https://45south.prolorus.app/Form/NewPublic/Application/harvest');

  return null;
}

