/* eslint-disable */

import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';


function AboutView() {

  const [show,setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    return (
      <span>
        <span className="af-view">
        <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet"></link>
          <div>
            <div className="af-class-section af-class-header-about af-class-wf-section">
              <div className="af-class-div-block-5">
                <h1 className="af-class-heading-12-seasonal">Our Story</h1>
                <h1 className="af-class-heading-13-_seasonal">About Us</h1>
              </div>
            </div>
            <div className="af-class-section-2 af-class-wf-section">
              <div className="af-class-div-block-6">
                <div className="af-class-div-block-7">
                  <div className="af-class-div-block-13">
                    <a className="af-class-lightbox-link w-inline-block w-lightbox"><img src="images/45south-play-icon.svg" alt="picture" role="button" onClick={() => handleShow() } />
                    </a>
                    <h1 className="af-class-heading-2">Watch our video</h1>
                    
                  </div>
                  <div className="youtube">
                  <Modal show={show} onHide={handleClose}>
        <Modal.Body><iframe width="460" height="315" src="https://www.youtube.com/embed/O9UGT3KuKM0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen" 
        msallowfullscreen="msallowfullscreen" 
        oallowfullscreen="oallowfullscreen" 
        webkitallowfullscreen="webkitallowfullscreen"></iframe></Modal.Body>
      </Modal>
         </div>
                </div>
              </div>
              <h2 className="af-class-heading_about_top">New Zealand’s biggest grower and packer of export cherries.</h2>
              <p className="af-class-paragraph-2">Established in 1984, 45 South Cherries is located on the outskirts of Cromwell in Central Otago. For over 35 years we’ve been the leader in growing, picking, packing and distributing NZ’s best cherry varieties.<br /><br />45 South Cherries’ packhouse and office is located in the Cromwell basin at Ripponvale. At the foot of Mt Pisa, this popular fruit growing location has produced many types of stone fruit over the past 100 years and is considered the ideal location for growing cherries in New Zealand. Mineral rich soils combined with Central Otago’s intense, Mediterranean climate deliver ruby red cherries with bold, delicious flavours. <br /><br />45 South Cherries manages over 20 client orchards throughout Central Otago.<br /></p>
            </div>
            <div className="af-class-section-16 af-class-wf-section" />
            <div className="af-class-section-3 af-class-wf-section">
              <h2 className="af-class-heading-14">Expert growers deliver the highest quality cherries in the world.</h2>
              <p className="af-class-paragraph-2">Central Otago’s diverse climate delivers a natural advantage in growing delicious cherries, however it also presents challenges to growers and orchardists. Our growers are exceptional orchardists, managing harsh winters and threats, such as hailstorms or heavy rain, which can devastate crops. Factors such as crop load, the amount of light a tree receives, nutrition and irrigation are closely monitored. Routine tasks such as pruning, trimming, fertilising and planting follow the dance of the seasons. <br /><br />Growing cherries in Central Otago is an integral part of our economy, heritage and culture. 45 South Cherries partners with the most passionate, skilled growers to ensure we can offer the highest quality cherries for export. Many of our orchards are family owned, with growing techniques handed down over generations.<br /><br />Key export varieties include seven of our most popular: Chelan, Sonnet, Stella, Lapin, Sweetheart and Staccato. 45 South Cherries also hold the exclusive rights to grow and export the late-ripening cherry variety Sentennial, a juicy, ruby-red cherry of the highest quality. Growing the Sentennial variety allows 45 South Cherries to supply markets longer than anyone else in New Zealand.<br /></p>
            </div>
            <div className="af-class-section-5 af-class-wf-section">
              <div className="af-class-container-3 w-container">
                <h2 className="af-class-heading-17"><strong className="af-class-bold-text">Meet the Management Team</strong></h2>
              </div>
              <div className="af-class-columns-4 w-row">
                <div className="af-class-column-11 w-col w-col-4 w-col-small-small-stack"><img src="images/Tim.png" alt="picture" />
                  <h3 className="af-class-heading-16">Tim Jones</h3>
                  <div className="af-class-text-block-4">CEO</div>
                  <a href="mailto:tim.jones@45s.co.nz?subject=Orchard%20Enquiry" className="af-class-link-2">tim.jones@45s.co.nz</a>
                </div>
                <div className="af-class-column-12 w-col w-col-4 w-col-small-small-stack"><img src="images/Joy_new_2023.png" alt="picture" className="af-class-image-34" />
                  <h3 className="af-class-heading-16">Joy Gillespie</h3>
                  <div className="af-class-text-block-4">Finance &amp; Administration Manager</div>
                  <a href="mailto:joy.gillespe@45s.co.nz?subject=Orchard%20Enquiry" className="af-class-link-2">joy.gillespie@45s.co.nz</a>
                </div>
                <div className="af-class-column-13 w-col w-col-4 w-col-small-small-stack"><img src="images/Richard.png" alt="picture" />
                  <h3 className="af-class-heading-16">Richard Cameron</h3>
                  <div className="af-class-text-block-4">Sales &amp; Marketing Manager</div>
                  <a href="mailto:richard@45s.co.nz?subject=Orchard%20Enquiry" className="af-class-link-2">richard@45s.co.nz</a>
                </div>
              </div>
            </div>
            <div className="af-class-section_four1 af-class-cta-about-page af-class-wf-section">
              <div className="af-class-div-block-3-copy">
                <div className="af-class-div-block-4-copy af-class-cta-about">
                  <div>
                    <h2 className="af-class-heading-10">Have a Question?<br />Let's Chat</h2>
                    <a href="contact" className="af-class-button-2 w-button">CONTACT&nbsp;US</a><img src="images/we-look-forward-to-hearing-from-you.png" alt="picture" className="af-class-image-7" />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-embed w-script">
            </div>
            {/* [if lte IE 9]><![endif] */}
            {/*  Google Tag Manager (noscript)  */}
            <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NCJNF4D" height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
            {/*  End Google Tag Manager (noscript)  */}
          </div>
        </span>
      </span>
    )
  }


export default AboutView

/* eslint-enable */